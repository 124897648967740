import revive_payload_client_MC7JJYlrvO from "/build/d1369451-0992-4f73-913a-18eb19b7068c/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._f42gmlmx2e3qhi2r4doihkxkh4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AGFrdDttb2 from "/build/d1369451-0992-4f73-913a-18eb19b7068c/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._f42gmlmx2e3qhi2r4doihkxkh4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Pal7wjqMFg from "/build/d1369451-0992-4f73-913a-18eb19b7068c/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._f42gmlmx2e3qhi2r4doihkxkh4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_DYSw7NbS77 from "/build/d1369451-0992-4f73-913a-18eb19b7068c/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._f42gmlmx2e3qhi2r4doihkxkh4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_7sJjcid13G from "/build/d1369451-0992-4f73-913a-18eb19b7068c/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._f42gmlmx2e3qhi2r4doihkxkh4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_RVVLeUlciV from "/build/d1369451-0992-4f73-913a-18eb19b7068c/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._f42gmlmx2e3qhi2r4doihkxkh4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_fWetQsa56g from "/build/d1369451-0992-4f73-913a-18eb19b7068c/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._f42gmlmx2e3qhi2r4doihkxkh4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/build/d1369451-0992-4f73-913a-18eb19b7068c/.nuxt/components.plugin.mjs";
import prefetch_client_iA762K3XZw from "/build/d1369451-0992-4f73-913a-18eb19b7068c/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.5.0_@types+node@22.10.1_eslint@9.16.0_jiti@2.4.0__ioredis@5._f42gmlmx2e3qhi2r4doihkxkh4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import auth_vT9JWWT9pN from "/build/d1369451-0992-4f73-913a-18eb19b7068c/plugins/auth.ts";
import autofocus_1LURlzQ7IF from "/build/d1369451-0992-4f73-913a-18eb19b7068c/plugins/autofocus.ts";
import chart_client_qQ92gke75l from "/build/d1369451-0992-4f73-913a-18eb19b7068c/plugins/chart.client.ts";
import dayjs_pGiXRjcsJO from "/build/d1369451-0992-4f73-913a-18eb19b7068c/plugins/dayjs.ts";
import dsfr_client_wnEKqTCG96 from "/build/d1369451-0992-4f73-913a-18eb19b7068c/plugins/dsfr.client.js";
import global_event_emitter_AhsutoD9go from "/build/d1369451-0992-4f73-913a-18eb19b7068c/plugins/global-event-emitter.ts";
import matomo_client_Ed2EqINkhd from "/build/d1369451-0992-4f73-913a-18eb19b7068c/plugins/matomo.client.ts";
import modal_UEFnUXzgTX from "/build/d1369451-0992-4f73-913a-18eb19b7068c/plugins/modal.ts";
import refresh_app_client_BPM4AstEHA from "/build/d1369451-0992-4f73-913a-18eb19b7068c/plugins/refresh-app.client.ts";
import sentry_client_shVUlIjFLk from "/build/d1369451-0992-4f73-913a-18eb19b7068c/plugins/sentry.client.ts";
import trpcClient_M8UiGTyzsx from "/build/d1369451-0992-4f73-913a-18eb19b7068c/plugins/trpcClient.ts";
export default [
  revive_payload_client_MC7JJYlrvO,
  unhead_AGFrdDttb2,
  router_Pal7wjqMFg,
  payload_client_DYSw7NbS77,
  navigation_repaint_client_7sJjcid13G,
  check_outdated_build_client_RVVLeUlciV,
  chunk_reload_client_fWetQsa56g,
  components_plugin_KR1HBZs4kY,
  prefetch_client_iA762K3XZw,
  auth_vT9JWWT9pN,
  autofocus_1LURlzQ7IF,
  chart_client_qQ92gke75l,
  dayjs_pGiXRjcsJO,
  dsfr_client_wnEKqTCG96,
  global_event_emitter_AhsutoD9go,
  matomo_client_Ed2EqINkhd,
  modal_UEFnUXzgTX,
  refresh_app_client_BPM4AstEHA,
  sentry_client_shVUlIjFLk,
  trpcClient_M8UiGTyzsx
]