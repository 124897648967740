import { default as emailsAAi8yzoT9PMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/emails.vue?macro=true";
import { default as integrationsuPYSWosvFvMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/integrations.vue?macro=true";
import { default as overviewza8tfehY7MMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/overview.vue?macro=true";
import { default as indexv9d9uXMNtVMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/structures/[id]/index.vue?macro=true";
import { default as subjectsSMLCDmorUQMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/structures/[id]/subjects.vue?macro=true";
import { default as indexLiGPAJvbwgMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/structures/index.vue?macro=true";
import { default as indexQJl6nosjcuMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/users/[id]/index.vue?macro=true";
import { default as addpqs0S0ps0DMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/users/add.vue?macro=true";
import { default as index4LhRIk7m4vMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/users/index.vue?macro=true";
import { default as adminKRaUw67hcJMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin.vue?macro=true";
import { default as accountWhUOoLHpm1Meta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/account.vue?macro=true";
import { default as index7pQb4i4HV1Meta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/archive/index.vue?macro=true";
import { default as entouragesYQ6D1stQSMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/entourage.vue?macro=true";
import { default as external_45organisationsWnhoMqq4gaMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/external-organisations.vue?macro=true";
import { default as healthG35jnZMf5VMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/health.vue?macro=true";
import { default as infoVvRokAWH4DMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/info.vue?macro=true";
import { default as occupation4I5ZPGCpjaMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/occupation.vue?macro=true";
import { default as tax_45householdNR2QYh9cxDMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/tax-household.vue?macro=true";
import { default as printveYqlxRYPdMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/print.vue?macro=true";
import { default as indexRivpR3lRmbMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/index.vue?macro=true";
import { default as editCIoaFKSpyzMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/budget/edit.vue?macro=true";
import { default as editdBMWkFvVMKMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/expenses/[eid]/edit.vue?macro=true";
import { default as edit5xleEXhU7lMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/followups/[fid]/edit.vue?macro=true";
import { default as addmdKZJQuXpGMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/followups/add.vue?macro=true";
import { default as edit965e2ngbrzMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue?macro=true";
import { default as add0oKYDXBBQBMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/help-requests/[subject]/add.vue?macro=true";
import { default as editWXiu7xYlMDMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue?macro=true";
import { default as addG3wCOPVFFKMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/help-requests/housing/add.vue?macro=true";
import { default as editdJ7kbHdZzTMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/income/[iid]/edit.vue?macro=true";
import { default as indexQOdEtoWMTVMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/index.vue?macro=true";
import { default as addF2QsFYbYG5Meta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/add.vue?macro=true";
import { default as historynJTXRAE8VGMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/history.vue?macro=true";
import { default as printoNAyKGGIjvMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/historyprint/[id]/print.vue?macro=true";
import { default as overviewAjPCCPkVdbMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/overview.vue?macro=true";
import { default as partnersd5CnTP5XKvMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/partners.vue?macro=true";
import { default as statsgdOgOAwW2fMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/stats.vue?macro=true";
import { default as indexKBwV9FPwdVMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/structure/index.vue?macro=true";
import { default as subjects3aYPGRw3DTMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/structure/subjects.vue?macro=true";
import { default as usersd7tKLa95hyMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/users.vue?macro=true";
import { default as appsr6Z2RMJzNMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app.vue?macro=true";
import { default as adminnH05BSi9R6Meta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/auth/admin.vue?macro=true";
import { default as loginkep6M7QxeRMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/auth/login.vue?macro=true";
import { default as logoutJDXAUR8LA8Meta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/auth/logout.vue?macro=true";
import { default as validate_45tokensHDrKzWxZDMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/auth/validate-token.vue?macro=true";
import { default as managerwReXyhx4gVMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/cgu/manager.vue?macro=true";
import { default as userLF8rZJlSzUMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/cgu/user.vue?macro=true";
import { default as cguCQmJ42TrZtMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/cgu.vue?macro=true";
import { default as declaration_45accessibiliteriAlfAO9aGMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/declaration-accessibilite.vue?macro=true";
import { default as disabled_45accountLAB52VDETIMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/error/disabled-account.vue?macro=true";
import { default as invalid_45tokeneDNZNBlz86Meta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/error/invalid-token.vue?macro=true";
import { default as unknown_45user_45icWU9XSww9MWMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/error/unknown-user-ic.vue?macro=true";
import { default as unknown_45usertN0L4pnI0mMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/error/unknown-user.vue?macro=true";
import { default as indexseJW1bgkzPMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/index.vue?macro=true";
import { default as mentions_45legaleshyjPNWINWMMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/mentions-legales.vue?macro=true";
import { default as politique_45confidentialitef2eHr9hna3Meta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/politique-confidentialite.vue?macro=true";
import { default as registerb6hleOgfC1Meta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/register.vue?macro=true";
import { default as statistiquesD8alJMpnhvMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/statistiques.vue?macro=true";
import { default as storieshmw8e2mvNMMeta } from "/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/stories.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminKRaUw67hcJMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin.vue"),
    children: [
  {
    name: "admin-emails",
    path: "emails",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/emails.vue")
  },
  {
    name: "admin-integrations",
    path: "integrations",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/integrations.vue")
  },
  {
    name: overviewza8tfehY7MMeta?.name ?? "admin-overview",
    path: "overview",
    meta: overviewza8tfehY7MMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/overview.vue")
  },
  {
    name: "admin-structures-id",
    path: "structures/:id()",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/structures/[id]/index.vue")
  },
  {
    name: "admin-structures-id-subjects",
    path: "structures/:id()/subjects",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/structures/[id]/subjects.vue")
  },
  {
    name: "admin-structures",
    path: "structures",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/structures/index.vue")
  },
  {
    name: "admin-users-id",
    path: "users/:id()",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/users/[id]/index.vue")
  },
  {
    name: "admin-users-add",
    path: "users/add",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/users/add.vue")
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/admin/users/index.vue")
  }
]
  },
  {
    name: "app",
    path: "/app",
    meta: appsr6Z2RMJzNMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app.vue"),
    children: [
  {
    name: "app-account",
    path: "account",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/account.vue")
  },
  {
    name: index7pQb4i4HV1Meta?.name ?? "app-beneficiaries-id-archive",
    path: "beneficiaries/:id()/archive",
    meta: index7pQb4i4HV1Meta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/archive/index.vue")
  },
  {
    name: "app-beneficiaries-id-edit-entourage",
    path: "beneficiaries/:id()/edit/entourage",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/entourage.vue")
  },
  {
    name: "app-beneficiaries-id-edit-external-organisations",
    path: "beneficiaries/:id()/edit/external-organisations",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/external-organisations.vue")
  },
  {
    name: "app-beneficiaries-id-edit-health",
    path: "beneficiaries/:id()/edit/health",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/health.vue")
  },
  {
    name: "app-beneficiaries-id-edit-info",
    path: "beneficiaries/:id()/edit/info",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/info.vue")
  },
  {
    name: occupation4I5ZPGCpjaMeta?.name ?? "app-beneficiaries-id-edit-occupation",
    path: "beneficiaries/:id()/edit/occupation",
    meta: occupation4I5ZPGCpjaMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/occupation.vue")
  },
  {
    name: "app-beneficiaries-id-edit-tax-household",
    path: "beneficiaries/:id()/edit/tax-household",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/edit/tax-household.vue")
  },
  {
    name: "app-beneficiaries-id-print",
    path: "beneficiaries/:id()/print",
    meta: printveYqlxRYPdMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/[id]/print.vue")
  },
  {
    name: indexRivpR3lRmbMeta?.name ?? "app-beneficiaries",
    path: "beneficiaries",
    meta: indexRivpR3lRmbMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/beneficiaries/index.vue")
  },
  {
    name: editCIoaFKSpyzMeta?.name ?? "app-files-id-budget-edit",
    path: "files/:id()/budget/edit",
    meta: editCIoaFKSpyzMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/budget/edit.vue")
  },
  {
    name: editdBMWkFvVMKMeta?.name ?? "app-files-id-expenses-eid-edit",
    path: "files/:id()/expenses/:eid()/edit",
    meta: editdBMWkFvVMKMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/expenses/[eid]/edit.vue")
  },
  {
    name: "app-files-id-followups-fid-edit",
    path: "files/:id()/followups/:fid()/edit",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/followups/[fid]/edit.vue")
  },
  {
    name: "app-files-id-followups-add",
    path: "files/:id()/followups/add",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/followups/add.vue")
  },
  {
    name: "app-files-id-help-requests-subject-hrid-edit",
    path: "files/:id()/help-requests/:subject()/:hrid()/edit",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/help-requests/[subject]/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-subject-add",
    path: "files/:id()/help-requests/:subject()/add",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/help-requests/[subject]/add.vue")
  },
  {
    name: "app-files-id-help-requests-housing-hrid-edit",
    path: "files/:id()/help-requests/housing/:hrid()/edit",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/help-requests/housing/[hrid]/edit.vue")
  },
  {
    name: "app-files-id-help-requests-housing-add",
    path: "files/:id()/help-requests/housing/add",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/help-requests/housing/add.vue")
  },
  {
    name: editdJ7kbHdZzTMeta?.name ?? "app-files-id-income-iid-edit",
    path: "files/:id()/income/:iid()/edit",
    meta: editdJ7kbHdZzTMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/income/[iid]/edit.vue")
  },
  {
    name: "app-files-id",
    path: "files/:id()",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/[id]/index.vue")
  },
  {
    name: "app-files-add",
    path: "files/add",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/files/add.vue")
  },
  {
    name: "app-history",
    path: "history",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/history.vue")
  },
  {
    name: "app-historyprint-id-print",
    path: "historyprint/:id()/print",
    meta: printoNAyKGGIjvMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/historyprint/[id]/print.vue")
  },
  {
    name: overviewAjPCCPkVdbMeta?.name ?? "app-overview",
    path: "overview",
    meta: overviewAjPCCPkVdbMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/overview.vue")
  },
  {
    name: "app-partners",
    path: "partners",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/partners.vue")
  },
  {
    name: "app-stats",
    path: "stats",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/stats.vue")
  },
  {
    name: "app-structure",
    path: "structure",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/structure/index.vue")
  },
  {
    name: "app-structure-subjects",
    path: "structure/subjects",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/structure/subjects.vue")
  },
  {
    name: "app-users",
    path: "users",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/app/users.vue")
  }
]
  },
  {
    name: "auth-admin",
    path: "/auth/admin",
    meta: adminnH05BSi9R6Meta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/auth/admin.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginkep6M7QxeRMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutJDXAUR8LA8Meta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/auth/logout.vue")
  },
  {
    name: "auth-validate-token",
    path: "/auth/validate-token",
    meta: validate_45tokensHDrKzWxZDMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/auth/validate-token.vue")
  },
  {
    name: "cgu",
    path: "/cgu",
    meta: cguCQmJ42TrZtMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/cgu.vue"),
    children: [
  {
    name: "cgu-manager",
    path: "manager",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/cgu/manager.vue")
  },
  {
    name: "cgu-user",
    path: "user",
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/cgu/user.vue")
  }
]
  },
  {
    name: "declaration-accessibilite",
    path: "/declaration-accessibilite",
    meta: declaration_45accessibiliteriAlfAO9aGMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/declaration-accessibilite.vue")
  },
  {
    name: "error-disabled-account",
    path: "/error/disabled-account",
    meta: disabled_45accountLAB52VDETIMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/error/disabled-account.vue")
  },
  {
    name: "error-invalid-token",
    path: "/error/invalid-token",
    meta: invalid_45tokeneDNZNBlz86Meta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/error/invalid-token.vue")
  },
  {
    name: "error-unknown-user-ic",
    path: "/error/unknown-user-ic",
    meta: unknown_45user_45icWU9XSww9MWMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/error/unknown-user-ic.vue")
  },
  {
    name: "error-unknown-user",
    path: "/error/unknown-user",
    meta: unknown_45usertN0L4pnI0mMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/error/unknown-user.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexseJW1bgkzPMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/index.vue")
  },
  {
    name: "mentions-legales",
    path: "/mentions-legales",
    meta: mentions_45legaleshyjPNWINWMMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/mentions-legales.vue")
  },
  {
    name: "politique-confidentialite",
    path: "/politique-confidentialite",
    meta: politique_45confidentialitef2eHr9hna3Meta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/politique-confidentialite.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerb6hleOgfC1Meta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/register.vue")
  },
  {
    name: "statistiques",
    path: "/statistiques",
    meta: statistiquesD8alJMpnhvMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/statistiques.vue")
  },
  {
    name: "stories",
    path: "/stories",
    meta: storieshmw8e2mvNMMeta || {},
    component: () => import("/build/d1369451-0992-4f73-913a-18eb19b7068c/pages/stories.vue")
  }
]